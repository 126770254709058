<template>
  <card class="custom-container">
    <div class="row p-4">
      <div class="col-12 col-lg-6 mx-auto">
        <form
          @submit.prevent="analisisXls()"
          class="d-flex flex-column align-items-center"
          style="gap: 1rem"
        >
          <!-- drop file -->
          <DropFileBasic
            @handleFileData="handleFileData"
            :loader="loader"
            :fileTypes="[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]"
          />

          <div class="w-100">
            <label for="empresa" class="form-label">Seleccionar empresa</label>
            <b-form-select
              id="empresa"
              required
              v-model="empresaSel"
              value-field="id"
              text-field="nombre"
              :options="listaEmpresas"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Selecciona una opcion --
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>

          <vs-button> Cargar perfil de cargo </vs-button>
        </form>
      </div>
    </div>
  </card>
</template>

<script>
import readXlsxFile from "read-excel-file";
import Swal from "sweetalert2";

import DropFileBasic from "@/components/helexium/DropFileBasic.vue";

export default {
  components: {
    DropFileBasic,
  },
  data() {
    return {
      usuario: {},
      listaEmpresas: [],
      empresaSel: null,
      titulos: [],
      file: null,
      loader: false,
    };
  },
  async mounted() {
    try {
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getEmpresas();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    handleFileData(data) {
      // console.log(data);
      this.file = data;
    },
    async analisisXls() {
      try {
        if (!this.file) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Debe cargar un archivo",
            confirmButtonText: "Aceptar",
          });
          return;
        }
        this.$isLoading(true);
        this.loader = true;
        //extracción de data xls
        let cargoId = this.file.name.split(".")[0];
        //declaración de variables
        let perfilCargoId = 0;
        let perfilCargo = {
          Id: 0,
          Codigo: "",
          Version: 0,
          CargoId: cargoId,
          Clasificacion: "",
          Critico: false,
          Objetivo: "",
          Observaciones: "",
          Implementacion: null,
          FechaActualizacion: null,

          Cargo: "",
          ResponsableInmediato: null,
          ProcesoId: 1,
          Nivel: null,
          Formacion: [],
          Experiencia: [],
          Funciones: [],
        };
        let FuncionesyResponsabilidades = [];
        console.log(perfilCargo);
        let rows = await readXlsxFile(this.file);
        let contador = 0;
        let funcionesyRespInit = 0;
        let funcionesyRespFin = 0;
        let observacionesIni = 0;
        let observacionesFin = 0;
        let funactual = "";
        let resactual = "";
        let objetivoText = "";
        let objetivosIni = 0;
        let codigoIni = 0;
        let versionIni = 0;
        let ImplementaciónIni = 0;
        let clasificacionIni = 0;
        let funcionId = null;

        let EducacionTexto = "";
        let EspecialidadETexto = "";
        let educacionIni = 0;
        let Educacion = {
          PerfilCargoId: 0,
          TituloId: 0,
          Especialidad: "",
          Titulo: "",
        };

        let FormacionIni = 0;
        let Formacion = {
          Id: null,
          PerfilCargoId: 0,
          Indispensable: null,
          Deseable: null,
          Descripcion: null,
          Titulo: null,
          TituloId: null,
          Especialidad: null,
        };
        let IndispensableIni = 0;
        let ExperienciaIni = 0;
        let Experiencia = {
          Especialidad: null,
          PerfilCargoId: null,
          MesesExperiencia: null,
          Homologacion: null,
          CargoCritico: null,
        };
        let HomologacionIni = 0;
        let CargoCriticoIni = 0;
        //////iniciando extracción especifica de datos x secciones
        for await (let item of rows) {
          //codigo
          if (item[8] != null && item[8].includes("Código")) {
            // console.log("item42----------", item);
            codigoIni = contador;
            console.log("codigoIni", codigoIni);
            perfilCargo.Codigo = item[9];
            console.log("codigo", item[9]);
          }
          //versionIni
          if (item[8] != null && item[8].includes("Versión")) {
            // console.log("item42----------", item);
            versionIni = contador;
            console.log("versionIni", versionIni);
            perfilCargo.Version = item[9] == null ? 0 : item[9];
            console.log("version", item[9]);
          }

          //Implementacion
          if (item[8] != null && item[8].includes("Implementación")) {
            // console.log("item42----------", item);
            ImplementaciónIni = contador;
            console.log("ImplementaciónIni", ImplementaciónIni);
            perfilCargo.Implementacion = this.$moment
              .tz(new Date(item[9]), "America/Costa_Rica")
              .format("YYYY-MM-DDTHH:mm:ss");
            console.log("ImplementaciónIni", perfilCargo.Implementacion);
          }
          //clasificacion
          if (
            item[8] != null &&
            item[8].includes("Clasificación de la información")
          ) {
            // console.log("item42----------", item);
            clasificacionIni = contador;
            console.log("clasificacionIni", clasificacionIni);
            perfilCargo.Clasificacion = item[9];
            console.log("clasificacion ", perfilCargo.Clasificacion);
          }
          //Fecha de Actualización
          if (item[2] != null && item[2].includes("Fecha de Actualización")) {
            // console.log("item42----------", item);
            perfilCargo.FechaActualizacion = this.$moment
              .tz(item[3], "America/Costa_Rica")
              .format("YYYY-MM-DDTHH:mm:ss");
            console.log("fecha actualizacion ", perfilCargo.FechaActualizacion);
          }
          //Educacion
          if (item[2] != null && item[2].includes("Educación")) {
            // console.log("item42----------", item);
            educacionIni = contador;
            console.log("educacionIni", educacionIni);
          }
          if (item[2] != null && item[2].includes("Formación")) {
            // console.log("item42----------", item);
            FormacionIni = contador;
            console.log("Formacion ini", FormacionIni);
          }
          if (item[2] != null && item[2].includes("Indispensable")) {
            // console.log("item42----------", item);
            IndispensableIni = contador;
            console.log("Indispensable ini", IndispensableIni);
          }
          if (item[2] != null && item[2].includes("Experiencia")) {
            // console.log("item42----------", item);
            ExperienciaIni = contador;
            console.log("experiencia ini", ExperienciaIni);
          }
          if (item[2] != null && item[2].includes("Homologación")) {
            // console.log("item42----------", item);
            HomologacionIni = contador;
            console.log("HomologacionIni ini", HomologacionIni);
          }
          if (item[2] != null && item[2].includes("¿Cargo Crítico?")) {
            // console.log("item42----------", item);
            CargoCriticoIni = contador;
            console.log("CargoCriticoIni ini", CargoCriticoIni);
          }

          //objetivo del cargo
          if (item[2] != null && item[2].includes("OBJETIVO DEL CARGO")) {
            // console.log("item42----------", item);
            objetivosIni = contador;
            //console.log("objetivosIni", objetivosIni);
          }
          //funciones y responsabilidades
          if (item[2] == "PRINCIPALES FUNCIONES Y RESPONSABILIDADES") {
            //console.log("item36----------", item);
            funcionesyRespInit = contador;
            //console.log("contador", funcionesyRespInit);
          }
          //funciones generales
          if (
            item[2] != null &&
            item[2].includes(
              "FUNCIONES GENERALES FRENTE A LOS SISTEMAS DE GESTIÓN"
            )
          ) {
            // console.log("item42----------", item);
            funcionesyRespFin = contador;
            //console.log("contador", funcionesyRespFin);
          }
          //observaciones
          if (
            item[2] != null &&
            item[2].includes("OBSERVACIONES (Otras especificaciones)")
          ) {
            // console.log("item42----------", item);
            observacionesIni = contador;
            //console.log("observaciones init", observacionesIni);
          }
          //observaciones fin
          if (item[2] != null && item[2].includes("NOMBRES Y APELLIDOS")) {
            // console.log("item42----------", item);
            observacionesFin = contador;
            //console.log("observacionesFin fin", observacionesFin);
          }

          //////////////////////////////////////////////////////////////////////
          //llenando variables
          //EDUCACION - ok
          // console.log("****************** LLENANDO VARIABLES!!!! *******************!");
          if (
            educacionIni > 0 &&
            FormacionIni == 0 &&
            contador > educacionIni &&
            item[2] != null
          ) {
            console.log(
              "****************** EDUCACION INICIAL *******************!"
            );
            //funciones
            if (item[2] != EducacionTexto && item[2] != null) {
              EducacionTexto = item[2];
              EspecialidadETexto = item[4];
            }

            console.log("EducacionTexto ", EducacionTexto);
            console.log("EspecialidadTexto ", EspecialidadETexto);
            Educacion.Titulo = EducacionTexto;
            Educacion.Especialidad = EspecialidadETexto;
            ///// aqui debo consumir servicio para obtener id de educacion, la tabla es [PerfilCargoFormacionTitulos]
          }
          //FORMACION - ok
          if (
            FormacionIni > 0 &&
            contador > FormacionIni &&
            IndispensableIni == 0 &&
            item[2] != null
          ) {
            console.log("****************** Formacion *******************!");
            //funciones
            if (item[2] != Formacion.Descripcion && item[2] != null) {
              Formacion.Descripcion = item[2];
            }

            console.log("FormacionTexto ", Formacion.Descripcion);
            ///// aqui debo consumir servicio para obtener id de educacion, la tabla es [PerfilCargoFormacionTitulos]
          }

          //INDISPENSABLE DESEABLE - ok
          if (IndispensableIni > 0 && contador == IndispensableIni) {
            console.log(
              "****************** indispensable *******************!"
            );
            if (item[3] == "X" || item[3] == "x")
              Formacion.Indispensable = true;
            else Formacion.Indispensable = false;

            if (item[5] == "X" || item[5] == "x") Formacion.Deseable = true;
            else Formacion.Deseable = false;

            console.log(
              "indispensable deseable  ",
              Formacion.Indispensable,
              " ",
              Formacion.Deseable
            );
          }

          //EXPERIENCIA - ok
          if (
            ExperienciaIni > 0 &&
            contador > ExperienciaIni &&
            HomologacionIni == 0 &&
            item[4] != null
          ) {
            console.log(
              "****************** Experiencia.Especialidad *******************!"
            );
            //funciones
            if (item[4] != Experiencia.Especialidad && item[4] != null) {
              Experiencia.Especialidad = item[4];
              //if(item[2] == '1 a 2 años'){
              let mesesDataSplit = item[2].split(" ");
              if (
                mesesDataSplit[1] == "meses" ||
                mesesDataSplit[1] == "mes" ||
                mesesDataSplit[1] == "mses"
              ) {
                Experiencia.MesesExperiencia = Number(mesesDataSplit[0]);
              } else {
                Experiencia.MesesExperiencia = Number(mesesDataSplit[0]) * 12;
              }
              //}
            }
            console.log("experiencia ", Experiencia.Especialidad);
            console.log("experiencia ", item[2]);
          }
          //HOMOLOGACION - ok
          if (
            HomologacionIni > 0 &&
            contador == HomologacionIni + 1 &&
            item[2] != null
          ) {
            console.log("****************** Homologación *******************!");
            //funciones
            if (item[2] != Experiencia.Homologacion && item[2] != null) {
              Experiencia.Homologacion = item[2];
            }
            console.log("Homologación  ", Experiencia.Homologacion);
          }

          //CARGO CRITICO - ok
          if (
            CargoCriticoIni > 0 &&
            contador == CargoCriticoIni &&
            item[2] != null
          ) {
            console.log(
              "****************** CARGO CRITICO *******************!"
            );
            //funciones
            // if (item[2] != Experiencia.Homologacion && item[2] != null) {
            //   Experiencia.Homologacion = item[2];
            // }
            // console.log("cargo critico  ", item[3]);
            // console.log("cargo critico  ", item[4]);
          }
          //EDUCACION - NO
          if (
            educacionIni > 0 &&
            FormacionIni == 0 &&
            contador > educacionIni &&
            item[2] != null
          ) {
            console.log(
              "****************** EDUCACION INICIAL *******************!"
            );
            //funciones
            if (item[2] != EducacionTexto && item[2] != null) {
              EducacionTexto = item[2];
              EspecialidadETexto = item[4];
            }

            console.log("EducacionTexto ", EducacionTexto);
            console.log("EspecialidadTexto ", EspecialidadETexto);
            Educacion.Titulo = EducacionTexto;
            Educacion.Especialidad = EspecialidadETexto;
            ///// aqui debo consumir servicio para obtener id de educacion, la tabla es [PerfilCargoFormacionTitulos]
          }
          //FORMACION - NO
          if (
            FormacionIni > 0 &&
            contador > FormacionIni &&
            IndispensableIni == 0 &&
            item[2] != null
          ) {
            console.log("****************** Formacion *******************!");
            //funciones
            if (item[2] != Formacion.Descripcion && item[2] != null) {
              Formacion.Descripcion = item[2];
            }

            console.log("FormacionTexto ", Formacion.Descripcion);
            ///// aqui debo consumir servicio para obtener id de educacion, la tabla es [PerfilCargoFormacionTitulos]
          }

          //INDISPENSABLE DESEABLE - NO
          if (IndispensableIni > 0 && contador == IndispensableIni) {
            console.log(
              "****************** indispensable *******************!"
            );
            if (item[3] == "X" || item[3] == "x")
              Formacion.Indispensable = true;
            else Formacion.Indispensable = false;

            if (item[5] == "X" || item[5] == "x") Formacion.Deseable = true;
            else Formacion.Deseable = false;

            console.log(
              "indispensable deseable  ",
              Formacion.Indispensable,
              " ",
              Formacion.Deseable
            );
          }

          //EXPERIENCIA - NO
          if (
            ExperienciaIni > 0 &&
            contador > ExperienciaIni &&
            HomologacionIni == 0 &&
            item[4] != null
          ) {
            console.log(
              "****************** Experiencia.Especialidad *******************!"
            );
            //funciones
            if (item[4] != Experiencia.Especialidad && item[4] != null) {
              Experiencia.Especialidad = item[4];
              //if(item[2] == '1 a 2 años'){
              let mesesDataSplit = item[2].split(" ");
              if (
                mesesDataSplit[1] == "meses" ||
                mesesDataSplit[1] == "mes" ||
                mesesDataSplit[1] == "mses"
              ) {
                Experiencia.MesesExperiencia = Number(mesesDataSplit[0]);
              } else {
                Experiencia.MesesExperiencia = Number(mesesDataSplit[0]) * 12;
              }
              //}
            }
            console.log("experiencia ", Experiencia.Especialidad);
            console.log("experiencia ", item[2]);
          }
          //HOMOLOGACION - NO
          if (
            HomologacionIni > 0 &&
            contador == HomologacionIni + 1 &&
            item[2] != null
          ) {
            console.log("****************** Homologación *******************!");
            //funciones
            if (item[2] != Experiencia.Homologacion && item[2] != null) {
              Experiencia.Homologacion = item[2];
            }
            console.log("Homologación  ", Experiencia.Homologacion);
          }

          //CARGO CRITICO - NO SE PUEDE EXTRAER
          if (
            CargoCriticoIni > 0 &&
            contador == CargoCriticoIni &&
            item[2] != null
          ) {
            console.log("****************** Homologación *******************!");
            //funciones
            // if (item[2] != Experiencia.Homologacion && item[2] != null) {
            //   Experiencia.Homologacion = item[2];
            // }
            // console.log("cargo critico  ", item[3]);
            // console.log("cargo critico  ", item[4]);
          }

          ///////////////////// fiN ////////////////////////

          //OBJETIVO - ok
          if (
            objetivosIni > 0 &&
            funcionesyRespInit == 0 &&
            contador > objetivosIni &&
            (item[2] != null || item[6] != null)
          ) {
            console.log("****************** OBJETIVO *******************!");
            //funciones
            if (item[2] != funactual && item[2] != null) {
              objetivoText = item[2];
            }
            console.log("OBJETIVOS ", objetivoText);
            perfilCargo.Objetivo = objetivoText;
          }

          //FUNCIONES Y RESPONSABILIDADES INSERT
          if (
            funcionesyRespInit > 0 &&
            funcionesyRespFin == 0 &&
            contador > funcionesyRespInit + 1 &&
            (item[2] != null || item[6] != null)
          ) {
            // console.log("*************************** FUNCIONES Y RESPONSABILIDADES ***********************");
            //funciones
            if (item[2] != funactual && item[2] != null) {
              funactual = item[2];
            }
            //responsabilidades
            resactual = item[6];
            // let sqlString = "insert into tabla ";
            FuncionesyResponsabilidades.push({
              funcion: funactual,
              responsabilidad: resactual,
            });
            // console.log('FUNCIONES Y RESPO',FuncionesyResponsabilidades);
          }

          ///FUNCIONES GENERALES INSERT - NO
          if (
            funcionesyRespInit > 0 &&
            funcionesyRespFin > 0 &&
            contador > funcionesyRespFin &&
            observacionesIni == 0 &&
            (item[2] != null || item[6] != null)
          ) {
            console.log(
              "*************************** FUNCIONES GENERALES ***********************"
            );

            //funciones
            if (item[2] != funactual && item[2] != null) {
              funactual = item[2];
            }
            //responsabilidades
            //resactual = item[6];
            //let funGeneralesStr = "insert into tabla funciones generales ";
            perfilCargo.Funciones.push({
              Id: null,
              CargoId: cargoId,
              Tipo: "G",
              Nombre: funactual,
            });
            console.log("FUNCIONES GENERALES ", perfilCargo.Funciones);
          }

          ///OBSERVACIONES - NO
          if (
            funcionesyRespInit > 0 &&
            funcionesyRespFin > 0 &&
            contador > funcionesyRespFin &&
            observacionesIni > 0 &&
            contador > observacionesIni &&
            observacionesFin == 0 &&
            (item[2] != null || item[6] != null)
          ) {
            console.log(
              "*************************** OBSERVACIONES ***********************"
            );

            //funciones
            if (item[2] != funactual && item[2] != null) {
              funactual = item[2];
            }
            perfilCargo.Observaciones = funactual;
            //en caso de que no exista se hace insert
            console.log(
              "perfilCargoId------------------------->",
              perfilCargoId
            );
          }

          contador++;
        }
        //
        //aqui debo hacer el insert de perfilCargo
        //primero verificar si ya existe un perfil del cargo en la db
        console.log("PERFIL DE CARGO DATA ", perfilCargo);
        console.log("PERFIL DE CARGO DATA ", cargoId);
        // let res = await this.$store.dispatch("hl_get", { path: "PerfilCargo/consultarPorCargo/" + cargoId });
        let res0 = await this.$store.dispatch("hl_get", {
          path: "PerfilCargo/consultarPorCargoV2/" + cargoId,
        });
        console.log("cargoid existe o no ? ******************", res0);
        if (res0.id) {
          perfilCargoId = res0.id;
        } else {
          perfilCargoId = 0;
        }
        console.log("cargoid existe o no ? ******************", perfilCargoId);
        //   console.log("se encontró perfil cargo", res);
        //   //   //si ya existe perfil debo actualizar perfil
        //   perfilCargoId = res.id;
        //   perfilCargo.Id = res.id;
        //   let resC = await this.$store.dispatch("hl_post", { path: "PerfilCargo/ActualizarPerfilCargo", data: perfilCargo });
        //     console.log("res crear perfil cargo", resC);
        //     // ingresar las funciones generales
        //     resC = await this.$store.dispatch("hl_post", { path: "PerfilCargo/CrearFuncionesGenerales", data: perfilCargo.Funciones });
        //     console.log("funciones generales", resC);
        // } else {
        // console.log("no se encontró perfil cargo, crear uno ", perfilCargo);
        // perfilCargo.Educacion = Educacion;
        // perfilCargo.Formacion = Formacion;
        // perfilCargo.Experiencia = Experiencia;
        delete perfilCargo.Formacion;
        delete perfilCargo.Educacion;
        delete perfilCargo.Experiencia;
        // let forma ={ Id: null, PerfilCargoId: 0, Indispensable: null, Deseable: null, Descripcion: null, Titulo: null, TituloId: null, Especialidad: null }
        // perfilCargo.Formacion = forma;
        perfilCargo.Id = perfilCargoId;
        perfilCargo.Formacion = null;
        perfilCargo.Educacion = null;
        perfilCargo.Experiencia = null;
        let res = await this.$store.dispatch("hl_post", {
          path: "PerfilCargo/crearPerfilCargoV2",
          data: perfilCargo,
        });
        if (res.status == 400) {
          console.log("res ", res);
          Swal.fire({
            icon: "error",
            title: `¡Error al cargar perfil de cargo!`,
            text: "Verifique los datos cargados",
          });
          this.$isLoading(false);
          this.loader = false;
          return;
        }
        console.log("res ", res);
        perfilCargoId = res;
        perfilCargo.Id = res;
        // }

        /// INSERT EDUCACION ///////////////////////
        console.log(funcionId);

        console.log("res ok", res);
        //procedemos con la insersion de datos...
        //educacion
        Educacion.PerfilCargoId = perfilCargoId;
        Educacion.Id = 0;
        Educacion.TituloId = 3;
        let EducacionObj = [];
        EducacionObj.push(Educacion);
        await this.getTitulos();
        console.log("titulos", this.titulos);
        let titulo = this.titulos.find((x) => x.nombre == Educacion.Titulo);
        Educacion.TituloId = titulo.id;
        let resEducacion = await this.$store.dispatch("hl_post", {
          path: "PerfilCargo/InsertarPerfilCargoEducacion",
          data: EducacionObj,
        });
        console.log("res educacion ", resEducacion);

        //////////////formacion
        Formacion.PerfilCargoId = perfilCargoId;
        Formacion.Id = 0;
        let FuncionObj = [];
        FuncionObj.push(Formacion);
        let resFormacion = await this.$store.dispatch("hl_post", {
          path: "PerfilCargo/InsertarPerfilCargoFormacion",
          data: FuncionObj,
        });
        console.log("res resFormacion ", resFormacion);

        ///////////////experiencia
        Experiencia.PerfilCargoId = perfilCargoId;
        Experiencia.Id = 0;
        Experiencia.CargoCritico = false;
        let ExperienciaObj = [];
        ExperienciaObj.push(Experiencia);

        console.log("experiencia --------------->", ExperienciaObj);
        let resExperienciaObj = await this.$store.dispatch("hl_post", {
          path: "PerfilCargo/InsertarPerfilCargoExperiencia",
          data: ExperienciaObj,
        });
        console.log("res resExperienciaObj ", resExperienciaObj);

        //   /////////////////////////////////

        ///////////// FUNCIONES Y RESPONSABILIDADES ///////////////////
        //afuera del for await hago las insersiones de funciones y responsabilidades
        let funcionActual = "";
        let tipoCompetenciaRes = await this.$store.dispatch("hl_post", {
          path: "TipoCompetencia/ListTipoCompetencia/",
        });
        tipoCompetenciaRes = tipoCompetenciaRes.find(
          (x) => x.descripcion == "Responsabilidad"
        );
        tipoCompetenciaRes = tipoCompetenciaRes.id;
        console.log("tipo competencia!!!", tipoCompetenciaRes);
        //obtener tipo competencia = responsabilidad

        for await (let itf of FuncionesyResponsabilidades) {
          console.log("funciones y responsabilidades", itf);
          //las responsabilidades son competencias
          //las funciones son funciones asi tal cual
          //primero insertar competencia
          //antes debo verificar si la compeencia existe en la db y que sea tipo resp

          let res = await this.$store.dispatch("hl_post", {
            path: "Competencia/ListCompetencia",
          });
          let buscaCompe = await res.find(
            (x) => x.titulo == itf.responsabilidad
          );
          let competenciaId = null;
          if (buscaCompe != undefined && buscaCompe.id) {
            console.log("******COMPETENCIA ENCONTRADA *******", buscaCompe);
            competenciaId = buscaCompe.id;
          } else {
            //insertar competencia y obtener id
            let dataCompetencia = {
              // Id: null,
              Titulo: itf.responsabilidad,
              Descripcion: itf.responsabilidad,
              TipoCompetenciaId: tipoCompetenciaRes,
              EmpresaId: this.empresaSel,
              Estado: 1,
            };
            console.log(
              "dataCompetencia *************************************",
              dataCompetencia
            );

            let res = await this.$store.dispatch("hl_post", {
              path: "Competencia/Create",
              data: dataCompetencia,
            });
            console.log("respuesta insersion competencia ---------->", res);
            competenciaId = res;
          }
          console.log("/////////////////vamos con cargo funcion/////////////");
          //despues insertar cargofuncion
          if (competenciaId != null) {
            if (funcionActual != itf.funcion) {
              //nueva funcion, hacer insert de funcion
              funcionActual = itf.funcion;
              let data = {
                CargoId: cargoId,
                Nombre: funcionActual,
                Tipo: null,
              };
              let res = await this.$store.dispatch("hl_post", {
                path: "PerfilCargo/InsertarFuncionCargoV2",
                data: data,
              });
              funcionId = res;
              console.log("id de la funcion agregada", funcionId);
            }
            //por ultimo insertar [CargoFuncionesResponsabilidades]

            let dataFunRes = {
              FuncionId: funcionId,
              CompetenciaId: competenciaId,
              CargoId: cargoId,
            };
            console.log(
              "datafunres *****************************>",
              dataFunRes
            );
            res = await this.$store.dispatch("hl_post", {
              path: "PerfilCargo/GuardarFuncionResponsabilidad",
              data: dataFunRes,
            });
            console.log("id de la funcion agregada", res);
          }
        }

        // /// FIN FUNCIONES Y RESPONSABILIDADES /////////////////////////////////////////////////

        this.loader = false;
        this.$isLoading(false);
      } catch (error) {
        this.loader = false;
        this.$isLoading(false);
        console.log("error analisis xls", error);
      }
    },
    async getTitulos() {
      this.titulos = await this.$store.dispatch("hl_get", {
        path: "PerfilCargo/titulos",
      });
    },
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async getEmpresas() {
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaEmpresas = res;
        } else {
          this.listaEmpresas = [];
        }
      } catch (error) {
        this.listaEmpresas = [];
        console.log("err", error);
      }
    },
  },
};
</script>
